<template>
	<b-card no-body>
		<b-card-header>
			<div class="d-flex align-items-center">
				<b-card-title class="ml-25 ">
					<span class="mr-50 bullet bullet-sm bullet-primary"/> {{$t('Price List')}}
				</b-card-title>
			</div>
			<div>
				<b-button variant="primary" size="sm" @click="newAction">{{$t('New Price')}}</b-button>
			</div>
		</b-card-header>
		<b-card-body>
			<b-table
				ref="priceList"
				hover
				:items="dataList"
				:fields="tableColumns"
				primary-key="id"
				show-empty
				:empty-text="$t('No Data')"
				stacked="sm"
				
			>
				<!-- Column: range -->
				<template #cell(range)="data">
					{{data.item.begin}} 至 {{data.item.end}}
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<!-- 编辑 -->
					<feather-icon
						:id="`${data.item.id}-edit-icon`"
						icon="EditIcon"
						class="cursor-pointer"
						size="16"
						@click="editAction(data.item)"
					/>
					<b-tooltip
						:title="$t('Edit')"
						class="cursor-pointer"
						:target="`${data.item.id}-edit-icon`"
					/>

					<!-- delete -->
					<feather-icon
						:id="`${data.item.id}-delete-icon`"
						icon="Trash2Icon"
						class="cursor-pointer ml-1"
						size="16"
						@click="deleteAction(data.item)"
					/>
					<b-tooltip
						:title="$t('Delete')"
						class="cursor-pointer"
						:target="`${data.item.id}-delete-icon`"
					/>
				</template>
			</b-table>
		</b-card-body>
		<New
			:is-active.sync="isNewSidebarActive"
			@refetch-data="getData(1)"
		/>
	</b-card>
</template>
<script>
import { BButton, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BImg, BTable, BTooltip } from 'bootstrap-vue'
import New from './PriceNew.vue'
export default {
	name:"Price",
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BImg,
		BCardHeader,BCardBody,
		BCardTitle,
		BTable,
		BTooltip,
		New
	},

  data () {
    return {
      tableColumns: [
        { key: 'id', },
        { key: 'type', label: this.$t('Type') },
        { key: 'range', label: this.$t('Time Range') },
        { key: 'price', label: this.$t('Price') },
        { key: 'updateTime', label: this.$t('Update Time') },
        { key: 'actions',label: this.$t('Actions') },
      ],
      dataList: [],
      isNewSidebarActive: false,
    }
  },
  created(){
    this.getData()
  },
  methods: {
    getData () {
      this.dataList = [{
        id:1, type: '整屏广告', updateTime: '2022-03-11 12:32', begin: "08:00", end: "12:00", price: "10"
      },{
        id:2, type: '整屏广告', updateTime: '2022-03-11 12:32', begin: "08:00", end: "12:00", price: "10"
      },{
        id:3, type: '整屏广告', updateTime: '2022-03-11 12:32', begin: "08:00", end: "12:00", price: "10"
      },{
        id:4, type: '整屏广告', updateTime: '2022-03-11 12:32', begin: "08:00", end: "12:00", price: "10"
      }]
    },
		newAction () {
			this.isNewSidebarActive = true
		},
    editAction(item){
      this.isNewSidebarActive = true
    },
    deleteAction(item){
      this.$bvModal
        .msgBoxConfirm(this.$t('deleteDataTip'), {
          title: this.$t('Tips'),//'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Delete'),
          cancelTitle: this.$t('Cancel'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          // 
        })
    }
	}
}
</script>