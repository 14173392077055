<template>
	<div class="pb-1">
		<!-- <h2>{{$t('Device Name')}}:Test</h2> -->
		<!-- <b-card :title="`${$t('Device Name')}：Test`"></b-card> -->
		<!-- 基本信息 -->
		<BaseInfo></BaseInfo>
		<!-- 商品描述 -->
		<Description />
		<!-- 价格列表 -->
		<Price></Price>
		<!-- 广告设置 -->
		<Setting />
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
	</div>
	
</template>
<script>
import BaseInfo from './BaseInfo.vue'
import Description from './Desc.vue'
import Price from './Price.vue'
import Setting from './Setting.vue'
export default {
  components: {
    BaseInfo,
	Description,
	Price,
	Setting
  },
    
}
</script>