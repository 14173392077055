<template>
	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{$t('Edit Base Info')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<!-- Title -->
					<validation-provider
						#default="validationContext"
						:name="$t('Title')"
						rules="required"
					>
						<b-form-group
							:label="$t('Title')"
							label-for="title"
						>
							<b-form-input
								id="title"
								v-model="formData.title"
								:state="getValidationState(validationContext)"
								trim
								:placeholder="$t('Please Enter...')"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Primary Image -->
					<b-form-group
						:label="$t('Primary Image')"
						label-for="primaryImg"
					>
						<image-uploader v-model="primaryImg" accept=".jpg, .png, .gif"></image-uploader>
					</b-form-group>

					<!-- Display Images -->
					<b-form-group
						:label="$t('Display Images')"
						label-for="displayImg"
					>
						<!-- <b-form-file
							id="displayImg"
							v-model="formData.imgs"
							accept=".jpg, .png, .gif"
						/> -->
						<image-uploader multiple v-model="imgList" accept=".jpg, .png, .gif"></image-uploader>
					</b-form-group>

					<!-- Size -->
					<validation-provider
						#default="validationContext"
						:name="$t('Size')"
						rules="required"
					>
						<b-form-group
							:label="$t('Size (Width * Height)')"
							label-for="size"
						>
							<b-row>
								<b-col cols="5" md="4">
									<b-form-input
										id="size"
										v-model="formData.title"
										autofocus
										:state="getValidationState(validationContext)"
										trim
										:placeholder="$t('Please Enter...')"
									/>
								</b-col>
								<span class="pt-75">*</span>
								<b-col cols="5" md="4">
									<b-form-input
										id="size"
										v-model="formData.title"
										autofocus
										:state="getValidationState(validationContext)"
										trim
										:placeholder="$t('Please Enter...')"
									/>
								</b-col>
							</b-row>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>
					
					<!-- DPI -->
					<validation-provider
						#default="validationContext"
						:name="$t('DPI')"
						rules="required"
					>
						<b-form-group
							:label="`${$t('DPI')}(px)`"
							label-for="dpi"
						>
							<b-row>
								<b-col cols="5" md="4">
									<b-form-input
										id="dpi"
										v-model="formData.dpi1"
										autofocus
										:state="getValidationState(validationContext)"
										trim
										:placeholder="$t('Please Enter...')"
									/>
								</b-col>
								<span class="pt-75">*</span>
								<b-col cols="5" md="4">
									<b-form-input
										id="size"
										v-model="formData.dpi2"
										autofocus
										:state="getValidationState(validationContext)"
										trim
										:placeholder="$t('Please Enter...')"
									/>
								</b-col>
							</b-row>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Address -->
					<validation-provider
						#default="validationContext"
						:name="$t('Address')"
						rules="required"
					>
						<b-form-group
							:label="$t('Address')"
							label-for="address"
						>
							<b-form-input
								id="address"
								v-model="formData.address"
								autofocus
								:state="getValidationState(validationContext)"
								trim
								:placeholder="$t('Please Enter...')"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Coordinate -->
					<validation-provider
						#default="validationContext"
						:name="$t('Coordinate')"
						rules="required"
					>
						<b-form-group
							:label="$t('Coordinate')"
							label-for="coordinate"
						>
							<b-row>
								<b-col cols="7" md="9">
									<b-form-input
										id="coordinate"
										v-model="formData.coordinate"
										autofocus
										:state="getValidationState(validationContext)"
										trim
										:placeholder="$t('Please Enter...')"
									/>
								</b-col>
								<b-col cols="5" md="3">
									<b-button block variant="primary">选择</b-button>
								</b-col>
							</b-row>
							

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Area -->
					<validation-provider
						#default="validationContext"
						:name="$t('Area')"
						rules="required"
					>
						<b-form-group
							:label="$t('Area')"
							label-for="content"
						>
							<v-select
								id="content"
								v-model="formData.area"
								:searchable="false"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="areaOptions"
								:placeholder="$t('Please Select')"
							>
							</v-select>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>
					
					
					<!-- Display Materials -->
					<validation-provider
						#default="validationContext"
						:name="$t('Display Materials')"
						rules="required"
					>
						<b-form-group
							:label="$t('Display Materials')"
							label-for="displayAsset"
						>
							<b-form-checkbox-group
								id="displayAsset"
								v-model="formData.assetType"
								:options="typeOptions"
								value-field="id"
								text-field="name"
							/>
							

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>



					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{$t('Add')}}
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{$t('Cancel')}}
							
						</b-button>
					</div>

				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import {mapState} from 'vuex'
import {
	BSidebar, BForm, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BButton,BFormDatepicker, BFormTimepicker, BFormCheckboxGroup,BRow,BCol
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import imageUploader from "@/components/public/ImageUploader"

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormFile,
		BFormInvalidFeedback,
		BButton,
		BFormDatepicker,
		BFormTimepicker,
		BFormCheckboxGroup,
		BRow,BCol,

		vSelect,

		imageUploader
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		}
	},
	computed: {
		...mapState({
			locale: state=>state.app.locale
		}),
	},
	data() {
		return {
			required,
			alphaNum,
			email,
			imgList: ['https://picsum.photos/250/250/?image=54','https://picsum.photos/250/250/?image=54'],
			primaryImg: ''
		}
	},
	created () {
	},
	methods: {
	},
	setup(props, { emit }) {
		const blankFormData = {
			content: '',
			begin: '',
			end: '',
			time: '',
			cycle: []
		}

		const formData = ref(JSON.parse(JSON.stringify(blankFormData)))
		const resetformData = () => {
			formData.value = JSON.parse(JSON.stringify(blankFormData))
		}

		const onSubmit = () => {
			store.dispatch('app-user/addUser', formData.value)
				.then(() => {
					emit('refetch-data')
					emit('update:is-active', false)
				})
		}

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetformData)

		const areaOptions = ['医院/社区/写字楼']
		const typeOptions = [
			{id:1, name: 'MP4'},
			{id:2, name: 'avi'},
			{id:3, name: 'wmv'},
			{id:4, name: 'mov'},
			{id:5, name: 'mkv'}
		]

		return {
			formData,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,

			areaOptions,
			typeOptions
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
