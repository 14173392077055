<template>
	<b-card no-body>
		<b-card-header>
			<div class="d-flex align-items-center">
				<b-card-title class="ml-25 ">
					<span class="mr-50 bullet bullet-sm bullet-primary"/> {{$t('Ad Setting')}}
				</b-card-title>
			</div>
			<div>
				<b-button class="mr-2" variant="primary" size="sm" @click="isNewSidebarActive=true">{{$t('Set')}}</b-button>
			</div>
		</b-card-header>
		<b-card-body>
			<b-table
				ref="priceList"
				hover
				:items="dataList"
				:fields="tableColumns"
				primary-key="id"
				show-empty
				:empty-text="$t('No Data')"
				stacked="sm"
                :busy="loading"
                
			>
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <!-- <strong>Loading...</strong> -->
                    </div>
                </template>
				<!-- Column: range -->
				<template #cell(content)="data">
					{{data.item.content? '' : '暂未设置'}}
				</template>
			</b-table>
		</b-card-body>
		<Edit
			:is-active.sync="isNewSidebarActive"
			@refetch-data="getData(1)"
		/>
	</b-card>
</template>
<script>
import { BButton, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BImg, BTable, BSpinner } from 'bootstrap-vue'
import Edit from './SettingEdit.vue'
export default {
	name:"Price",
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BImg,
		BCardHeader,BCardBody,
		BCardTitle,
        BTable,
        BSpinner,

		Edit
	},
    data () {
        return {
            tableColumns: [
                { key: 'type', label: this.$t('Type') ,width:'8%'},
                { key: 'content', label: this.$t('Setting Content') },
            ],
            dataList: [],
            loading: false,
            isNewSidebarActive: false
        }
    },
    created(){
        this.getData()
    },
    methods: {
        getData () {
            this.loading = true
            setTimeout(()=>{
                this.loading = false
            },3000)
            this.dataList = [{
                id:1, type: '整屏广告', content: ''
            },{
                id:2, type: '文字广告', content: ''
            },{
                id:3, type: '窗口广告', content: ''
            }]
        },
    }
}
</script>