<template>
	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		width="500px"
		@hidden="resetForm"
		@change="(val) => $emit('update:is-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{$t('New Price')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>

					<!-- Type -->
					<validation-provider
						#default="validationContext"
						:name="$t('Type')"
						rules="required"
					>
						<b-form-group :label="$t('Type')" label-for="type">
							<v-select
								id="type"
								v-model="formData.type"
								:searchable="false"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="typeOptions"
								:placeholder="$t('Please Select')"
							>
							</v-select>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Primary Image -->
					<b-form-group
						:label="$t('Primary Image')"
						label-for="primaryImg"
					>
						<image-uploader v-model="primaryImg" accept=".jpg, .png, .gif"></image-uploader>
					</b-form-group>
					<!-- 窗口广告 属性设置 start -->
					<template v-if="formData.type=='窗口广告'">
						<!-- Name -->
						<validation-provider
							#default="validationContext"
							:name="$t('Window Name')"
							rules="required"
						>
							<b-form-group
								:label="$t('Window Name')"
								label-for="title"
							>
								<b-form-input
									id="title"
									v-model="formData.title"
									:state="getValidationState(validationContext)"
									trim
									:placeholder="$t('Please Enter...')"
								/>

								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- 位置 -->
						<validation-provider
							#default="validationContext"
							:name="$t('Location')"
							rules="required"
						>
							<b-form-group
								:label="`${$t('Location')} (X,Y)`"
								label-for="size"
							>
								<b-row>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>X：</span>
											<b-form-input
												id="size"
												v-model="formData.x"
												:state="getValidationState(validationContext)"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
										<b-form-invalid-feedback>
											{{ validationContext.errors[0] }}
										</b-form-invalid-feedback>
									</b-col>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>Y：</span>
											<b-form-input
												id="size"
												v-model="formData.y"
												:state="getValidationState(validationContext)"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
										<b-form-invalid-feedback>
											{{ validationContext.errors[0] }}
										</b-form-invalid-feedback>
									</b-col>
								</b-row>

								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- Size -->
						<validation-provider
							#default="validationContext"
							:name="$t('Window Size')"
							rules="required"
						>
							<b-form-group
								:label="`${$t('Window Size')} (mm)`"
								label-for="size"
							>
								<b-row>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>宽：</span>
											<b-form-input
												id="size"
												v-model="formData.width"
												:state="getValidationState(validationContext)"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
										<b-form-invalid-feedback>
											{{ validationContext.errors[0] }}
										</b-form-invalid-feedback>
									</b-col>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>高：</span>
											<b-form-input
												id="size"
												v-model="formData.height"
												:state="getValidationState(validationContext)"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
										<b-form-invalid-feedback>
											{{ validationContext.errors[0] }}
										</b-form-invalid-feedback>
									</b-col>
								</b-row>

								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
						
						<!-- DPI -->
						<validation-provider
							#default="validationContext"
							:name="$t('DPI')"
							rules="required"
						>
							<b-form-group
								:label="`${$t('DPI')}(px)`"
								label-for="dpi"
							>
								<b-row>
									<b-col cols="5" md="4">
										<b-form-input
											id="dpi"
											v-model="formData.dpi1"
											:state="getValidationState(validationContext)"
											trim
											:placeholder="$t('Please Enter...')"
										/>
									</b-col>
									<span class="pt-75">*</span>
									<b-col cols="5" md="4">
										<b-form-input
											id="size"
											v-model="formData.dpi2"
											:state="getValidationState(validationContext)"
											trim
											:placeholder="$t('Please Enter...')"
										/>
									</b-col>
								</b-row>

								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
					</template>
					<!-- 窗口广告 属性设置 end -->
					
					<!-- 价格列表 start -->
					<h6>价格列表：</h6>
					<b-card class="pa-2 time-list">
						<div class="time-item" v-for="(item,index) in timeList" :key="index">
							<b-form-group
								:label="`${$t('Period')}`"
							>
								<v-select
									v-model="formData.time"
									:searchable="false"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="timeOptions"
									label="name"
									:placeholder="$t('Please Select')"
								>
								</v-select>
							</b-form-group>
							<b-form-group
								:label="$t('Price')"
							>
								<b-input-group append="元/秒" class="input-group-merge">
									<b-form-input
										v-model="formData.dpi2"
										trim
										:placeholder="$t('Please Enter...')"
									/>
								</b-input-group>
							</b-form-group>
							<feather-icon
								icon="Trash2Icon"
								class="oper cursor-pointer ml-1 text-primary"
								size="16"
								v-if="index>0"
								@click="deleteAction(index)"
							/>
						</div>
						<p class="text-center">
							<b-button
								variant="primary"
								class="mr-2"
								size="sm"
								@click="addTime"
							>
								{{$t('Add Period')}}
							</b-button>
						</p>
					</b-card>
					
					
					<!-- 价格列表 end -->

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{$t('Add')}}
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{$t('Cancel')}}
							
						</b-button>
					</div>

				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import {mapState} from 'vuex'
import {
	BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BFormInvalidFeedback, BButton,BFormDatepicker, BFormTimepicker, BFormCheckboxGroup, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import imageUploader from "@/components/public/ImageUploader"
import {formatDate} from '@/libs/utils'

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BFormInvalidFeedback,
		BButton,
		BFormDatepicker,
		BFormTimepicker,
		BFormCheckboxGroup,
		BRow, BCol,
		BCard,

		vSelect,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
		imageUploader
		
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		}
	},
	computed: {
		...mapState({
			locale: state=>state.app.locale
		}),
		calendarLocale () {
			if(this.locale=='zh_CN'){
				return 'zh'
			}
			return this.locale
		}
	},
	data() {
		return {
			required,
			alphaNum,
			email,
			today: formatDate(new Date(),'YYYY-MM-DD'),
			langs: {
				'zh': {
					labelPrevDecade: '过去十年',
					labelPrevYear: '上一年',
					labelPrevMonth: '上个月',
					labelCurrentMonth: '当前月份',
					labelNextMonth: '下个月',
					labelNextYear: '明年',
					labelNextDecade: '下一个十年',
					labelToday: '今天',
					labelSelected: '选定日期',
					labelNoDateSelected: '未选择日期',
					labelCalendar: '日历',
					labelNav: '日历导航',
					labelHelp: '使用光标键浏览日期',
					labelHours: '小时',

					
				},
			},
			timerLangs:{
				zh: {
					labelMinutes: '分钟',
					labelSeconds: '秒',
					labelAmpm: '上午下午',
					labelAm: '上午',
					labelPm: '下午',
					labelIncrement: '增量',
					labelDecrement: '减量',
					labelSelected: '选定时间',
					labelNoTimeSelected: '没有选择时间',
					labelCloseButton: '关',
				}
			},
			primaryImg: '',
			timeList: [{}]
		}
	},
	created () {
		console.log(this.calendarLocale)
		// localize('zh_CN',zh_CN)
	},
	methods: {
		addTime () {
			this.timeList.push({})
		},
		deleteAction(index){
			this.timeList.splice(index,1)
		}
	},
	setup(props, { emit }) {
		const blankFormData = {
			content: '',
			begin: '',
			end: '',
			time: '',
			type: '窗口广告'
		}

		const formData = ref(JSON.parse(JSON.stringify(blankFormData)))
		const resetformData = () => {
			formData.value = JSON.parse(JSON.stringify(blankFormData))
		}

		const onSubmit = () => {
			store.dispatch('app-user/addUser', formData.value)
				.then(() => {
					emit('refetch-data')
					emit('update:is-active', false)
				})
		}

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetformData)

		const typeOptions = ['窗口广告','文本广告','整屏广告']
		const timeOptions = [
			{id:1, name: '08:00-12:00'},
		]

		return {
			formData,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,

			typeOptions,
			timeOptions
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.time-list{
	border: 1px solid #ddd;
	.time-item{
		position: relative;
		border-bottom: 1px solid #ddd;
		margin-bottom: 20px;
		padding-bottom: 10px;
		padding-right: 30px;
		.oper{
			position: absolute;
			top: 0px;
			right: 0;
		}
	}
}
.divider{
	display: block;
	width: 100%;
	height: 1px;
	background: #ddd;
	border:none
}
</style>
