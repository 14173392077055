<template>
	<b-card no-body>
		<b-card-header>
			<div class="d-flex align-items-center">
				<b-card-title class="ml-25 ">
					<span class="mr-50 bullet bullet-sm bullet-primary"/> {{$t('Base Info')}}
				</b-card-title>
			</div>
			<div>
				<b-button class="mr-2" variant="primary" size="sm" @click="editAction">编辑</b-button>
				<b-button variant="outline-danger" size="sm">下架</b-button>
			</div>
		
		</b-card-header>

		<b-card-body>
			<b-row>
				<b-col cols="6" :md="2">
					<b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
				</b-col>
				<b-col cols="6" :md="2">
					<b-img thumbnail fluid src="https://picsum.photos/250/250/?image=58" alt="Image 2"></b-img>
				</b-col>
				<b-col cols="6" :md="2">
					<b-img thumbnail fluid src="https://picsum.photos/250/250/?image=59" alt="Image 3"></b-img>
				</b-col>
			</b-row>
			<b-row class="mt-2">
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('Title')}}</h5>
					<p>Test</p>
				</b-col>
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('Size')}}</h5>
					<p>1920 * 1080</p>
				</b-col>
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('DPI')}}</h5>
					<p>1920px * 1080px</p>
				</b-col>
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('Status')}}</h5>
					<p class="text-success">{{$t('Online')}}</p>
				</b-col>
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('Address')}}</h5>
					<p>深圳市XXXXXXXX</p>
				</b-col>
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('Coordinate')}}</h5>
					<p>117,2223 , 38.888</p>
				</b-col>
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('Area')}}</h5>
					<p>医院/社区/写字楼</p>
				</b-col>
				<b-col cols="6" md="3" lg="2">
					<h5>{{$t('Display Materials')}}</h5>
					<p>mp4,avi,wmv,mov,mkv</p>
				</b-col>
			</b-row>
		</b-card-body>
		<Edit
			:is-active.sync="isEditSidebarActive"
			@refetch-data="getData(1)"
		/>
	</b-card>
</template>
<script>
import { BButton, BRow, BCol, BCard, BCardHeader,BCardTitle, BCardBody, BImg } from 'bootstrap-vue'
import Edit from './BaseInfoEdit.vue'
export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BImg,
		BCardHeader,
		BCardTitle,
		BCardBody,
		Edit
	},
	data () {
		return {
			isEditSidebarActive: false
		}
	},
	methods: {
		editAction () {
			this.isEditSidebarActive = true
		}
	}
}
</script>