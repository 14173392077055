
<style scoped lang="scss">
.image-uploader{
    display: flex;
    flex-wrap: wrap;
    .img-item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border:1px solid #eee;
        color: #999;
        img{
            max-width: 100%;
            max-height: 100%;
        }
        .oper-blk{
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.4);
            color: #fff;
        }
        &:hover{
            .oper-blk{
                display: flex;
            }
        }
        .file-ipt{
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
        }
    }
    .img-list{
        display: inline-flex;
    }
}
</style>
<template>
    <div class="image-uploader">
        <draggable
            v-model="imgList"
            v-bind="dragOptions"
            tag="div"
            class="img-list cursor-move"
            @end="onDragEnd"
            >
                <div class="img-item mr-1" v-for="(item,index) in imgList" :key="index">
                    <img :src="item" alt="">
                    <div class="oper-blk">
                        <div>
                            <!-- <feather-icon
                                icon="ZoomInIcon"
                                class="cursor-pointer mr-1"
                                size="24"
                                @click="previewAction(index)"
                            /> -->
                            <feather-icon
                                icon="Trash2Icon"
                                class="cursor-pointer"
                                size="24"
                                @click="deleteAction(index)"
                            />
                        </div>
                    </div>
                </div>
        </draggable>
        <label class="img-item upload-item">
            <input :accept="accept" class="file-ipt cursor-pointer" type="file" @change="fileChange">
            <feather-icon
                icon="PlusIcon"
                class="cursor-pointer"
                size="40"
            />
        </label>
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
    components: {
        draggable
    },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        fileList: {
            default: []
        },
        accept: {
            default: ''
        }
    },
    model: {
        prop: 'fileList',
        event: 'update:list',
    },
    data () {
        return {
            dragOptions: {
                animation: 300,
                group: "description",
                ghostClass: "ghost"
            },
            imgList: []
        }
    },
    created () {
        if(this.multiple){
            this.imgList = this.fileList
        }else if(this.fileList){
            this.imgList = [this.fileList]
        }
        
    },
    methods: {
        previewAction (index){},
        deleteAction (index){
            this.$bvModal.msgBoxConfirm(this.$t('deleteDataTip'), {
                title: this.$t('Tips'),//'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('Delete'),
                cancelTitle: this.$t('Cancel'),
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {
                this.imgList.splice(index,1)
                this.$emit('update:list',this.imgList)
            })
        },
        fileChange (e) {
            console.log(e.target.files)
        },
        onDragEnd () {
            this.$emit('update:list',this.imgList)
        }
    }
}
</script>