<template>
	<b-card no-body>
		<b-card-header>
			<div class="d-flex align-items-center">
				<b-card-title class="ml-25 ">
					<span class="mr-50 bullet bullet-sm bullet-primary"/> {{$t('Product Description')}}
				</b-card-title>
			</div>
			<div>
				<b-button variant="primary" size="sm" @click="$router.push('/deviceAdDesc')">{{$t('Edit')}}</b-button>
				<!-- <b-button variant="outline-primary" size="sm">{{$t('View')}}</b-button> -->
			</div>
		</b-card-header>
		<b-card-body>
			媒体位于广州市白云区金沙洲沈海高速广告，附近有中国联通、金匠世家、乐购购物广场、必胜客、茂业百货、太阳城等，人流量大，且具有极大的影响力。周边商场林立，居民集中，显示屏所处位置人口高度密集，车水马龙，热闹非凡。媒体周边的高端商业消费人群、商旅人士及旅游观光人群；有较高的受众人群注意率，地理位置绝佳，瞬间传达，被动受众。媒体离地高速约15m，周围无干扰，视觉冲击力强。是各企业想要短期内打造知名度的首选高性价比黄金LED媒体。
		</b-card-body>
	</b-card>
</template>
<script>
import { BButton, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BImg } from 'bootstrap-vue'

export default {
	name:"Description",
  components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BImg,
		BCardHeader,BCardBody,
		BCardTitle,
  },
	data () {
		return {
			isEditSidebarActive: false
		}
	}
}
</script>