<template>
	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		width="500px"
		@hidden="resetForm"
		@change="(val) => $emit('update:is-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{$t('Ad Setting')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
			<div class="p-2">
				<b-tabs justified v-model="activeTabIndex" @input="tabChange">
					<!-- 整屏广告 -->
					<b-tab :title="$t('Full Screen Advertising')">
						<b-form-group :label="$t('Time Interval')">
							<b-input-group
								:append="$t('Minute')"
								class="input-group-merge"
							>
								<b-form-input
									v-model="formData.dpi2"
									trim
									type="number"
									:placeholder="$t('Please Enter...')"
								/>
							</b-input-group>
						</b-form-group>
						<b-form-group :label="$t('Advertising Duration')">
							<b-input-group
								:append="$t('Second')"
								class="input-group-merge"
							>
								<b-form-input
									v-model="formData.dpi2"
									trim
									type="number"
									:placeholder="$t('Please Enter...')"
								/>
							</b-input-group>
						</b-form-group>
					</b-tab>
					<!-- 窗口广告 -->
					<b-tab :title="$t('Window Advertising')">
						<div class="window-item" v-for="(item,index) in windowList" :key="index">
							<h6 class="mb-1">{{$t('Window')}}{{index+1}}</h6>
							<!-- 位置 -->
							<b-form-group
								:label="`${$t('Location')} (X,Y)`"
								label-for="size"
							>
								<b-row>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>X：</span>
											<b-form-input
												id="size"
												v-model="item.x"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
									</b-col>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>Y：</span>
											<b-form-input
												id="size"
												v-model="item.y"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
									</b-col>
								</b-row>
							</b-form-group>

							<!-- Size -->
							<b-form-group
								:label="`${$t('Window Size')} (mm)`"
								label-for="size"
							>
								<b-row>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>宽：</span>
											<b-form-input
												id="size"
												v-model="item.width"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
									</b-col>
									<b-col cols="5" md="4">
										<div class="d-flex align-items-center">
											<span>高：</span>
											<b-form-input
												id="size"
												v-model="item.height"
												trim
												:placeholder="$t('Please Enter...')"
											/>
										</div>
									</b-col>
								</b-row>
							</b-form-group>
							<feather-icon
								icon="Trash2Icon"
								class="oper cursor-pointer ml-1 text-primary"
								size="16"
								v-if="index>0"
								@click="removeWindow(index)"
							/>
						</div>
						<p class="text-center">
							<b-button
								variant="primary"
								size="sm"
								@click="addWindow"
							>
								{{$t('Add Window')}}
							</b-button>
						</p>
					</b-tab>
					<!-- 文本广告 -->
					<b-tab :title="$t('Text Advertising')">
						<!-- 位置 -->
						<b-form-group
							:label="`${$t('Location')} (X,Y)`"
							label-for="size"
						>
							<b-row>
								<b-col cols="5" md="4">
									<div class="d-flex align-items-center">
										<span>X：</span>
										<b-form-input
											id="size"
											v-model="formData.x"
											trim
											:placeholder="$t('Please Enter...')"
										/>
									</div>
								</b-col>
								<b-col cols="5" md="4">
									<div class="d-flex align-items-center">
										<span>Y：</span>
										<b-form-input
											id="size"
											v-model="formData.y"
											trim
											:placeholder="$t('Please Enter...')"
										/>
									</div>
								</b-col>
							</b-row>
						</b-form-group>

						<!-- Size -->
						<b-form-group
							:label="`${$t('Window Size')} (mm)`"
							label-for="size"
						>
							<b-row>
								<b-col cols="5" md="4">
									<div class="d-flex align-items-center">
										<span>宽：</span>
										<b-form-input
											id="size"
											v-model="formData.width"
											trim
											:placeholder="$t('Please Enter...')"
										/>
									</div>
								</b-col>
								<b-col cols="5" md="4">
									<div class="d-flex align-items-center">
										<span>高：</span>
										<b-form-input
											id="size"
											v-model="formData.height"
											trim
											:placeholder="$t('Please Enter...')"
										/>
									</div>
								</b-col>
							</b-row>
						</b-form-group>
					</b-tab>
				</b-tabs>
				<!-- Form Actions -->
				<div class="d-flex mt-2">
					<b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						variant="primary"
						class="mr-2"
						type="submit"
					>
						{{$t('Add')}}
					</b-button>
					<b-button
						v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						type="button"
						variant="outline-secondary"
						@click="hide"
					>
						{{$t('Cancel')}}
						
					</b-button>
				</div>
			</div>
			
		</template>
	</b-sidebar>
</template>

<script>
import {mapState} from 'vuex'
import {
	BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BFormInvalidFeedback, BButton, BRow, BCol, BCard, BTabs, BTab
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BFormInvalidFeedback,
		BButton,
		BRow, BCol,
		BCard,
		BTabs, BTab,

		vSelect,

		// Form Validation
		ValidationProvider,
		ValidationObserver,		
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		}
	},
	computed: {
	},
	data() {
		return {
			required,
			activeTabIndex: 0,
			windowList: [{}]
		}
	},
	created () {
	},
	methods: {
		tabChange (e) {
			console.log(e)
			console.log(this.activeTabIndex)
		},
		addWindow () {
			this.windowList.push({})
		},
		removeWindow (index) {
			this.windowList.splice(index,1)
		}
	},
	setup(props, { emit }) {
		const blankFormData = {
			content: '',
			begin: '',
			end: '',
			time: '',
			cycle: []
		}

		const formData = ref(JSON.parse(JSON.stringify(blankFormData)))
		const resetformData = () => {
			formData.value = JSON.parse(JSON.stringify(blankFormData))
		}

		const onSubmit = () => {
			store.dispatch('app-user/addUser', formData.value)
				.then(() => {
					emit('refetch-data')
					emit('update:is-active', false)
				})
		}

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetformData)

		const typeOptions = ['窗口广告','文本广告','整屏广告']
		const timeOptions = [
			{id:1, name: '08:00-12:00'},
		]

		return {
			formData,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,

			typeOptions,
			timeOptions
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.window-item{
	position: relative;
	border:1px solid #ddd;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 15px;
	.oper{
		position: absolute;
		top: 10px;
		right: 10px;
	}
}
</style>
